import { Controller } from "stimulus"

export default class extends Controller {
  goto(evt) {
    const href = this.data.get('href')
    if (event.metaKey) {
      window.open(href)
    }
    else {
      location.href = href
    }
    evt.stopPropagation()
  }
}
