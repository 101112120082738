function timeSince(timeStamp) {
  const now = Date.now()/1000
  const secondsPast = now - timeStamp
  if (secondsPast < 60) {return `${parseInt(secondsPast)}s`}
  if (secondsPast < 3600) {return `${parseInt(secondsPast/60)}m`}
  if (secondsPast < 86400) {return `${parseInt(secondsPast/3600)}h`}
  return new Date(timeStamp*1000).toLocaleDateString()
}

document.querySelectorAll('.time').forEach(elt=>{
  const timeStamp = elt.dataset.time
  const now = Date.now()/1000
  if (timeStamp && timeStamp > (now-86400)) {
    elt.textContent = timeSince(timeStamp)
  }
})

export default timeSince