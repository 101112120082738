// Visit The Stimulus Handbook for more details 
// https://stimulusjs.org/handbook/introduction
// 
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import "@babel/polyfill"
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "progress", 'mythreads' ]
  async connect() {
    if (Cookies.get('is_logged')) {
      console.log('show progress')
      this.progressTarget.style.display = 'Block'
      const response = await fetch('/twitter_threads/mythreads')
      if (response.status == 200) {
        const html = await response.text()
        this.mythreadsTarget.innerHTML = html
        this.mythreadsTarget.style.display = 'Block'
        this.progressTarget.style.display = 'none'  
      }
      else {
        this.mythreadsTarget.style.display = 'Block'
        this.progressTarget.style.display = 'none'
      }
    }
    else {
      this.mythreadsTarget.style.display = 'Block'
    }
  }

}
