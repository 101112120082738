(function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
  (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
  m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
})(window,document,'script','https://www.google-analytics.com/analytics.js','ga');
ga('create', google_analytics, 'auto');
const uid = Cookies.get('uid')
if (uid) {ga('set', 'userId', uid)}
ga('send', 'pageview', window.gaPath || undefined);

document.querySelectorAll('.gaClickEvent').forEach(elt=>{
  elt.addEventListener('click', evt=>{
    const {category, action, label, value} = elt.dataset
    console.log('gaEvent', category, action, label, value)
    ga('send', 'event', category, action, label, value)
  })
})

if (window.gaReadyCallback) {gaReadyCallback()}

export default ga