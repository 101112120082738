import { Controller } from "stimulus"
import MetaPreview from '../lib/meta'

export default class extends Controller {
  connect() {
    const parentElt = this.element
    keep43ForAll(parentElt)
    window.addEventListener('resize', ()=>keep43ForAll(parentElt))
    checkAvailableThread(parentElt)
    previewLinks(parentElt.querySelectorAll('a[data-preview]'))
  }

  showTweet(evt) {
    const {screenname, tweet} = evt.currentTarget.dataset
    const doDefaultClick = eltHasClass(evt.target, ['content-tweet','t-main','t-tweet']) || evt.target.tagName == 'I'
    if (doDefaultClick && !getSelection().toString()) {
      window.open(`https://twitter.com/${screenname}/status/${tweet}`, '_blank')
    }
  }
}

const eltHasClass = (elt, classArray)=>
  classArray.find(classname=>elt.classList.contains(classname))

function keep43Ratio(elt) {
  elt.style.height = elt.scrollWidth*3/4 + 'px'
}

function keep43ForAll(parentElt) {
  parentElt.querySelectorAll('.keep-ratio-4-3').forEach(elt=>keep43Ratio(elt))
}

async function availableTraId(statusId) {
  try {
    const r = await fetch(`/api/v0/ping/${statusId}.json`)
    const result = await r.json()
    console.log('got pong', result.pong)
    return result.pong  
  }
  catch (e) {
    return null
  }
}

async function checkAvailableThread(parentElt) {
  parentElt.querySelectorAll('.twitter-player a').forEach(async anchorElt=>{
    const link = anchorElt.href
    const twitterSpan = anchorElt.closest('.twitter-player')
    const match = link && link.match(/^https?:\/\/(x|twitter)\.com\/\w{1,15}\/status\/(\d+)/)
    if (twitterSpan && match && match[2]) {
      const statusId = match[2]
      const availableId = await availableTraId(statusId)
      if (availableId) {
        const url = `/thread/${availableId}.html`
        if (twitterSpan) {
          twitterSpan.innerHTML = `<div class="ribbon-content"><div class="ribbon base"><span><a href="${url}">Unroll available on Thread Reader</a></span></div></div>` + twitterSpan.innerHTML
        }
      }
    }
  })
}

function previewLinks(links) {
  const linksArray = Array.prototype.slice.call(links)
  const existingUrls = []
  const uniqueLinks = linksArray.filter((link, index, self)=>{
    const url = link.getAttribute('href')
    if (existingUrls.includes(url)) {
      return false
    }
    else {
      existingUrls.push(url)
      return true
    }
  })
  uniqueLinks.forEach(link=>MetaPreview.fetchAndCreate(link))
}
