import { Controller } from "stimulus"

export default class extends Controller {
  click(evt) {
    if (window.isPremium) {
      document.querySelector('#email_this').classList.add('open');
      document.querySelector('#share_this').classList.remove('open')
      evt.preventDefault()
      evt.stopPropagation()
      return false
    }
  }
  async send_email(evt) {
    evt.preventDefault()
    evt.stopPropagation()
    const form = this.element
    if (form.reportValidity()) {
      const formBody = new URLSearchParams(new FormData(this.element)).toString()
      try {
        await fetch('/twitter_threads/send_email', {
          method: 'POST',
          headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8' },
          body: formBody
        })
        document.body.classList.add('email_friend_sent')
      }
      catch (e) {
        console.error('err in sending email')
      }
    }
  }
  connect() {
    console.log(this.element)
  }
}
