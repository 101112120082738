import "@babel/polyfill"
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "container" ]

  async load() {
    const {container} = this  
    if (container.classList.contains('no_more')) {return;}
    const lastCard = container.lastElementChild
    const before = parseInt(lastCard.querySelector('span.time').dataset.time,10)
    const offset = lastCard.dataset.offset
    this.data.set('loading', true)
    console.log('loading...', before)
    let url = this.data.get('url')
    url += (url.indexOf('?') >= 0) ? '&' : '?'
    url += `before=${before}`
    if (offset) {
      url += `&offset=${offset}`
    }
    const data = await fetch(url)
    const html = await data.text()
    if (html.trim().length > 0) {
      container.innerHTML += html
      new Blazy({selector:'.b-lazy,.entity-image img'});
    }
    else {
      container.classList.add('no_more')
    }
    this.data.delete('loading')
  }

  get container() {
    return this.containerTarget 
  }

  willLoad(event) {
    if (this.data.get('loading')) {
      console.log('still loading')
      return;
    }
    const {container} = this
    const {bottom} = container.getBoundingClientRect()
    // console.log('willLoad', bottom, window.innerHeight)
    if (bottom < (window.innerHeight+40)) {
      this.load()
    }
  }
}
 