import { Controller } from "stimulus"
export default class extends Controller {
  connect() {
    const self = this
    const {element} = this
    const mentionLink = document.createElement('a')
    mentionLink.textContent = '@mentions'
    mentionLink.classList.add('btn-mentions')
    const clickFn = this.show.bind(this)
    element.querySelectorAll('.entity-mention-first').forEach(link=>{
      const parentElt = link.parentElement
      if (!parentElt.zzInserted) {
        parentElt.zzInserted = true
        const cloneLink = mentionLink.cloneNode(true)
        cloneLink.addEventListener('click',clickFn)
        parentElt.insertBefore(cloneLink, link)
      }
    })
  }
  show() {
    const {element} = this
    element.classList.remove('hide-mentions')
  }
}