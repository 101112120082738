import { Controller } from "stimulus"

export default class extends Controller {
  async connect() {
    console.log('connected!')
    const tweetId = this.element.dataset.tweet
    if (!sessionStorage.getItem(`tweet_${tweetId}`)) {
      const response = await fetch(`/stats/inc/${tweetId}`, {method:'POST', headers:{'Content-Type':'application/json'}})
      const data = await response.json()
      window.zed = data
      if (data && data.total > 99) {
        this.element.textContent = `${data.total.toLocaleString()} views`
        const relatedElt = document.getElementById('related')
        if (relatedElt && data.related) {
          relatedElt.innerHTML += data.related
          relatedElt.style.display = ''
        }
      }
      sessionStorage.setItem(`tweet_${tweetId}`, true)
    }
  }
}

