import { Controller } from "stimulus"

export default class extends Controller {
  error(evt) {
    this.refetchImageSrc(evt.target)
  }
  refetchImageSrc(img) {
    if (!img.dataset.retried && img.dataset.twtrid) {
      let prefix = '/images/avatars/'
      if (img.dataset.banner) {
        prefix += 'banner/'
      }
      img.src = `${prefix}${img.dataset.twtrid}?refresh=${Date.now()}`
      console.log('img.src', img.src)
      img.dataset.retried = true
    }
    else {
      img.src = img.dataset.banner ? '/assets/og-no-banner.png' : '/images/default_avatar.png'
    }
    console.log('refetchImageSrc', img.dataset.twtrid, img.dataset.banner)
  }
  connect() {
    const img = this.element
    if (img.complete && img.naturalWidth === 0) {
      this.refetchImageSrc(img)
    }
  }
}
