import { Controller } from "stimulus"

export default class extends Controller {
  areYouSure(evt) {
    const msg = this.data.get('message') || 'Are you sure?'
    if (!confirm(msg)) {
      evt.preventDefault()
      evt.stopPropagation()
    }
  }
}
 