const defaultImageUrl = '/images/sticky-note-regular.png'
class MetaPreview {
  constructor(link) {
    this.link = link
    this.url = link.getAttribute('href')
  }
  async fetchAndCreate() {
    await this.fetchMeta()
    this.createPreview()
  }
  async fetchMeta() {
    const r = await fetch(`/og/?url=${encodeURIComponent(this.url)}`)
    const metaInfo = await r.json()
    if (metaInfo.description && metaInfo.description.length > 200) {
      metaInfo.description = metaInfo.description.substring(0,200) + '…'
    }
    this.metaInfo = Object.assign({url:this.url, description:'', title:''}, metaInfo)
    this.fixImageUrl()
  }
  fixImageUrl() {
    
    try {
      if (this.metaInfo.image) {
        const url = new URL(this.metaInfo.image)  
      }
    }
    catch(e) {
      try {
        const docUrl = new URL(this.url)
        const url = new URL(this.metaInfo.image, docUrl)
        this.metaInfo.image = url.toString()
      }
      catch(e) {
        // give up
      }
    }
  }
  makePreview() {
    const {title, url, image, description} = this.metaInfo
    if (!(title || image || description)) {
      return false
    }
    return this.previewHtml = `
<div class="entity-url-preview">
    <div class="d-flex justify-content-between align-items-center">
        <div class="border-right align-self-center">
            <a target="_blank" href="${url}" class="img-cover">
                <img src="${image}" loading="lazy" onerror="this.src='${defaultImageUrl}'" />
            </a>
        </div>
        <div class="flex-grow-1" style="min-width:0">
            <div class="paragraph">
                <a target="_blank" href="${url}">
                    <strong>${title}</strong>
                    ${description}
                </a>
                <a target="_blank" href="${url}"><small class="pre-url">${this.url}</small></a>
            </div>
            
        </div>
    </div>
</div>
`
  }
  createPreview() {
    if (this.makePreview()) {
      const {link, previewHtml} = this
      const wrap = document.createElement("div");
      wrap.innerHTML = previewHtml;
      const endElt = link.parentNode.querySelector('.tw-permalink')
      const linkIsAtEnd = link.nextElementSibling == endElt && link.nextSibling && link.nextSibling.textContent.trim() == ''
      link.parentNode.appendChild(wrap) 
      if (linkIsAtEnd) {link.style.display = 'none'}  
    }
  }
}

MetaPreview.fetchAndCreate = link=>(new MetaPreview(link)).fetchAndCreate()

export default MetaPreview
// @@ fetch err
// no image
