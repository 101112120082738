import { Controller } from "stimulus"

export default class extends Controller {
static targets = [ "sidebar" ]

  connect() {
    const currentHref = document.location.toString().split('#')[0]
    document.querySelectorAll('.nav-item').forEach(elt=>{
      if (elt.href == currentHref) {
        elt.classList.add('active')
      }
    })
  }

  open() {
    this.sidebarTarget.style.width = "250px"
  }

  close() {
    this.sidebarTarget.style.width = "0"
  }
}
 